import React, { useState, Suspense } from "react";
import Button from "../../Atoms/Button/Button";
import "./store.css";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import Diabetes from "../../Assets/diabetes.png";
import Arthritis from "../../Assets/arthritis.png";
import Backpain from "../../Assets/backpain.png";
import Pcos from "../../Assets/Pcos.jpeg";
import Hypertension from "../../Assets/hypertension.jpeg";
import Prenetal from "../../Assets/prenetal.png";
import LiveClassesGraphic from "../../Graphics/LiveClassesGraphic";
import NewLogo from "../../Graphics/NewLogo";
import OneOnOneGraphic from "../../Graphics/OneOnOneGraphic";
import FilterClasses from "./FilterPages/Search";
import FilterDatasEnum from "../../Utilities/FilterData";
import useIsMobile from "../../Hooks/useMobile";
import MyLearnings from "../MyLearnings";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { UserApi } from "../../Api/UserApi";
import { StorefrontApi } from "../../Api/StorefrontApi";
import TeacherDomainPage from "../TeacherDomainPage";
import UrlService from "../../Services/UrlService";
import SubscriptionList from "./SubscriptionList";
import YogaSun from "../../Graphics/YogaSun";
import Banners from "./Banners";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Styles from "../../Constants/styles";
import ReviewsList from "./ReviewList";
import { useForm } from "react-hook-form";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import CourseAction from "../../Actions/course";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ReactWhatsapp from "react-whatsapp";
import Faq from "./FAQ";
import BannerDiet from "../Diet/images/banner.svg";
import BannerYoga from "../YogaNidra/images/yogalogo.svg";
import Google from "../YogaNidra/images/google.svg";
import Apple from "../YogaNidra/images/apple.svg";

import HomeFoot from "../footer/HomeFoot";

const OneOnOnes = React.lazy(() => import("./OneOnOne_v2"));
const BlogPage = React.lazy(() => import("./BlogList"));
const MonthlyClasses = React.lazy(() => import("./MonthlyBeginnerFilter"));
const CardTabs = React.lazy(() => import("./CardTabs"));

export const ViewAllComponent = ({ link, name }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(CourseAction.setViewAll([]));
    ReactGA.event({
      category: "View All Course",
      action: "Routing",
      label: name,
    });
    ReactPixel.track("track", {
      category: "View All Course",
      action: "Routing",
      label: name,
    });
    history.push({
      pathname: link,
    });
  };
  return (
    <div
      className={`flex ${
        isMobile ? "justify-end" : "justify-center"
      } items-center px-0 pt-3 cursor-pointer`}
      onClick={handleClick}
    >
      <div className="pr-2 text-base text-y-yellow-dark font-bold">
        View All
      </div>
      <div>
        <svg
          width="25"
          height="25"
          viewBox="0 0 16 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.624989 4.50001L13.8612 4.50001L11.6686 6.68201C11.4239 6.9255 11.423 7.32122 11.6665 7.56588C11.91 7.81056 12.3057 7.81147 12.5504 7.568L15.8161 4.31801C15.8163 4.31782 15.8165 4.31761 15.8167 4.31742C16.0607 4.07392 16.0615 3.67692 15.8167 3.43261C15.8165 3.43242 15.8164 3.4322 15.8162 3.43201L12.5504 0.182024C12.3058 -0.0614138 11.9101 -0.0605698 11.6665 0.184148C11.423 0.428803 11.424 0.824521 11.6686 1.06802L13.8612 3.25001L0.624989 3.25001C0.279801 3.25001 -1.14441e-05 3.52983 -1.14441e-05 3.87501C-1.14441e-05 4.2202 0.279801 4.50001 0.624989 4.50001Z"
            fill="#E56F2C"
          />
        </svg>
      </div>
    </div>
  );
};

export default function Home() {
  const history = useHistory();
  const isMobile = useIsMobile();
  let countryCode = "";
  const { register, handleSubmit } = useForm({});
  const teacherDomain = UrlService.getTeacherDomain();
  const { callApi, data } = useApi(UserApi.getUpcomingClasses);

  const {
    loading,
    callApi: createUserApi,
    errorMessage,
  } = useApi(UserApi.createTherapyUser);

  function onSubmit(data) {
    createUserApi({ ...data, course: "Home" })
      .then((res) => {
        countryCode === "IN"
          ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
          : window.open("https://buy.stripe.com/5kAbLl8aw5vC3ao5kk", "_blank");
        // Swal.fire({
        //   icon: "success",
        //   title: "Success",
        //   text: "Data Saved Successfully",
        //   confirmButtonText: "Okay",
        // }).then((result) => {
        //   countryCode === "IN"
        //     ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
        //     : window.open(
        //         "https://buy.stripe.com/5kAbLl8aw5vC3ao5kk",
        //         "_blank"
        //       );
        //   // if (result.isConfirmed) {
        //   //   history.push("/");
        //   // }
        // });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  }

  const { callApi: searchCreateUrl } = useApi(StorefrontApi.createSearchUrl);

  const [showFilter, setShowFilter] = useState(false);
  const [queryText, setQueryText] = useState("");

  const handleClick = (e) => {
    const nameText = "Monthly Yoga Courses";
    const searchUrl = `?name=${queryText}&type=month&courseName=${nameText}`;

    if ((e.key === "Enter") | !e.key) {
      searchCreateUrl({ url_string: searchUrl }).then((res) => {
        history.push({
          pathname: "/courses-all",
          search: `?query=${res?.data?.data.url_id}`,
        });
      });
    }
  };

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  const healthConditionsData = [
    {
      name: "Pregnancy",
      pic: Prenetal,
    },
    {
      name: "PCOS",
      pic: Pcos,
    },
    {
      name: "Diabetes",
      pic: Diabetes,
    },
    {
      name: "Back Pain",
      pic: Backpain,
    },
    {
      name: "Arthritis",
      pic: Arthritis,
    },
    {
      name: "Hypertension",
      pic: Hypertension,
    },
  ];

  const timings = [
    { text: "Early Morning", text1: "5 - 7am", link: "/courses/early-morning" },
    { text: "Afternoon", text1: "1 - 4pm", link: "/courses/afternoon" },
    { text: "Late Morning", text1: "8 - noon", link: "/courses/morning" },
    { text: "Evening", text1: "5 - 9pm", link: "/courses/evening" },
  ];

  const timingsData = [
    { text: "Early Morning", text1: "5 - 7am", link: "/courses/early-morning" },
    { text: "Late Morning", text1: "8 - noon", link: "/courses/morning" },
    { text: "Afternoon", text1: "1 - 4pm", link: "/courses/afternoon" },
    { text: "Evening", text1: "5 - 9pm", link: "/courses/evening" },
  ];

  const customTimes = [
    {
      label: "00:00",
      value: "23:00-01:00",
    },
    {
      label: "00.30",
      value: "23:30-01:30",
    },
    {
      label: "01:00",
      value: "00:00-02:00",
    },
    {
      label: "01:30",
      value: "00:30-02:30",
    },
    {
      label: "02:00",
      value: "01:00-03:00",
    },
    {
      label: "02:30",
      value: "01:30-03:30",
    },
    {
      label: "03:00",
      value: "02:00-04:00",
    },
    {
      label: "03:30",
      value: "02:30-04:30",
    },
    {
      label: "04:00",
      value: "03:00-05:00",
    },
    {
      label: "04:30",
      value: "03:30-05:30",
    },
    {
      label: "05:00",
      value: "04:00-06:00",
    },
    {
      label: "05:30",
      value: "04:30-06:30",
    },
    {
      label: "06:00",
      value: "05:00-07:00",
    },
    {
      label: "06:30",
      value: "05:30-07:30",
    },
    {
      label: "07:00",
      value: "06:00-08:00",
    },
    {
      label: "07:30",
      value: "06:30-08:30",
    },
    {
      label: "08:00",
      value: "07:00-09:00",
    },
    {
      label: "08:30",
      value: "07:30-09:30",
    },
    {
      label: "09:00",
      value: "08:00-10:00",
    },
    {
      label: "09:30",
      value: "08:30-10:30",
    },
    {
      label: "10.00",
      value: "09:00-11:00",
    },
    {
      label: "10:30",
      value: "09:30-11:30",
    },
    {
      label: "11:00",
      value: "10:00-12:00",
    },
    {
      label: "11:30",
      value: "10:30-12:30",
    },
    {
      label: "12:00",
      value: "11:00-13:00",
    },
    {
      label: "12:30",
      value: "11:30-13:30",
    },
    {
      label: "13:00",
      value: "12:00-14:00",
    },
    {
      label: "13:30",
      value: "12:30-14:30",
    },
    {
      label: "14:00",
      value: "13:00-15:00",
    },
    {
      label: "14:30",
      value: "13:30-15:30",
    },
    {
      label: "15:00",
      value: "14:00-16:00",
    },
    {
      label: "15:30",
      value: "14:30-16:30",
    },
    {
      label: "16:00",
      value: "15:00-17:00",
    },
    {
      label: "16:30",
      value: "15:30-17:30",
    },
    {
      label: "17:00",
      value: "16:00-18:00",
    },
    {
      label: "17:30",
      value: "16:30-18:30",
    },
    {
      label: "18:00",
      value: "17:00-19:00",
    },
    {
      label: "18:30",
      value: "17:30-19:30",
    },
    {
      label: "19:00",
      value: "18:00-20:00",
    },
    {
      label: "19:30",
      value: "18:30-20:30",
    },
    {
      label: "20:00",
      value: "19:00-21:00",
    },
    {
      label: "20:30",
      value: "19:30-21:30",
    },
    {
      label: "21:00",
      value: "20:00-22:00",
    },
    {
      label: "21:30",
      value: "20:30-22:30",
    },
    {
      label: "22:00",
      value: "21:00-23:00",
    },
    {
      label: "22:30",
      value: "21:30-23:30",
    },
    {
      label: "23:00",
      value: "22:00-00:00",
    },
    {
      label: "23:30",
      value: "22:30-00:30",
    },
  ];

  if (window.COUNTRY_CODE) {
    countryCode = window.COUNTRY_CODE;
  }

  const handleClickHealthConditions = (item) => {
    history.push({
      pathname: `/courses/health/${item.name}`,
    });
  };

  React.useEffect(() => {
    callApi();
    pageTracking();
    ReactPixel.pageView();
  }, [callApi]);

  const upcomingData = data?.data || [];

  if (teacherDomain) {
    return <TeacherDomainPage domain={teacherDomain} />;
  }

  const SearchBanner = () => {
    return (
      <div
        className={cx(
          "flex flex-row justify-between items-end overflow-x-auto",
          "h-28 sm:h-full sm:w-4/5 mb-3"
        )}
      >
        <div className="text-lg md:text-xl text-y-yellow-dark font-bold pl-0 pr-0 md:pr-2 pt-0 md:pt-5 sm:w-2/4">
          <h1 className="mt-0 pt-2">Do Yoga. Be Healthy.</h1>
          <p
            className="pt-1 pb-0 text-black text-base font-bold"
            style={{ fontSize: "14px" }}
          >
            100+ online yoga classes.
          </p>
          <p
            className="pt-1 pb-0 text-black text-base font-bold"
            style={{ fontSize: "14px" }}
          >
            Book a trial class at just {countryCode === "IN" ? "Rs.50" : "$5"}
          </p>

          <div
            class="md:pl-0 mt-8 hidden md:block flex flex-row pb-3 relative text-black w-full"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <input
              class="w-full pl-10 border-2 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none"
              type="text"
              name="search"
              placeholder="Explore all of yoga"
              onKeyDown={handleClick}
              autocomplete="off"
              value={queryText}
              onChange={(e) => {
                setQueryText(e.target.value);
              }}
              style={{ borderColor: "rgb(229, 111, 44)" }}
            />
            <button
              type="submit"
              class="absolute mt-2 top-1 left-3"
              onClick={handleClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25px"
                height="25px"
                viewBox="0 0 50 50"
                style={{ fill: "rgb(229, 111, 44)" }}
              >
                <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
              </svg>
            </button>
          </div>
          {/* <div className="hidden md:block pt-3">
            <div className="flex flex-row justify-between text-black text-sm font-medium px-1 text-center font-bold">
              <div
                role="button"
                onClick={() => {
                  history.push("/courses/live-classes");
                }}
              >
                Live & <br /> Interactive
              </div>
              <div
                role="button"
                onClick={() => {
                  history.push("/courses/all-levels");
                }}
              >
                Yoga for <br /> all levels
              </div>
              <div
                role="button"
                onClick={() => {
                  history.push("/courses/all-health-conditions");
                }}
              >
                For your <br /> health needs
              </div>
            </div>
          </div> */}
        </div>

        <div className="">
          {isMobile ? (
            <div style={{ position: "absolute", right: 10, top: 90 }}>
              <NewLogo
                width={145}
                height={105}
                className="landing-girl-new-animate"
              />
            </div>
          ) : (
            <NewLogo
              width={!isMobile ? 280 : 145}
              height={!isMobile ? 130 : 95}
              className="landing-girl-new-animate"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="px-0 flex flex-col w-full">
        <Helmet>
          <title>Do Yoga - Online Yoga Classes</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="title" content="Do Yoga - Online Yoga Classes" />
          <meta
            name="description"
            content="Online yoga classes guided by the best yoga teachers in India."
          />
          <meta
            property="og:title"
            content="Do Yoga - Online Yoga Classes"
          ></meta>
          <meta
            property="twitter:title"
            content="Do Yoga - Online Yoga Classes"
          ></meta>
          <meta
            property="og:description"
            content="Online yoga classes guided by the best yoga teachers in India."
          ></meta>
          <meta
            property="twitter:description"
            content="Online yoga classes guided by the best yoga teachers in India."
          ></meta>
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="twitter:url" content={window.location.href}></meta>
        </Helmet>
        {upcomingData.length > 0 ? (
          <>
            <div
              className={cx(
                "inline-block cursor-pointer text-lg md:text-xl",
                "font-semibold",
                "text-y-yellow-dark px-0 mt-3 mb-3"
              )}
            >
              Your Upcoming Classes
            </div>
            <div className="w-full px-0 mt-0">
              <MyLearnings showTabs={false} />
            </div>
          </>
        ) : null}
        {upcomingData.length > 0 ? (
          isMobile ? null : (
            <>{SearchBanner()}</>
          )
        ) : (
          <>{SearchBanner()}</>
        )}
        {showFilter ? (
          <div className="sm:px-0">
            <FilterClasses
              showFilter={showFilter}
              filterText={queryText}
              setShowFilter={() => {
                setShowFilter(false);
              }}
            />
          </div>
        ) : null}
        {isMobile && (
          <div
            style={{
              position: "absolute",
              top: 15,
              right: 120,
              display: "none",
            }}
          >
            <button
              type="submit"
              class="absolute mt-2 top-1 left-3"
              onClick={() => setShowFilter(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="25px"
                height="25px"
                viewBox="0 0 50 50"
                style={{ fill: "rgb(229, 111, 44)" }}
              >
                <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
              </svg>
            </button>
          </div>
        )}
        {/* <div
          onClick={() => {
            setShowFilter(true);
          }}
          class="mt-0 px-0 w-full md:pl-2 block md:hidden pb-0 relative text-black"
        >
          <input
            class="w-full pl-10 border-2 border-gray-300 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none"
            type="text"
            name="search"
            autocomplete="off"
            placeholder="Explore all of yoga"
            onKeyDown={handleClick}
            value={queryText}
            onChange={(e) => {
              setQueryText(e.target.value);
            }}
          />
          <button
            type="submit"
            class="absolute mt-2 top-1 left-3"
            onClick={handleClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25px"
              height="25px"
              viewBox="0 0 50 50"
              style={{ fill: "grey" }}
            >
              <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
            </svg>
          </button>
        </div> */}
        {/* <div className="block md:hidden ">
          <div className="flex flex-row justify-between text-black text-sm font-medium px-0 text-center font-bold">
            <div
              role="button"
              onClick={() => {
                history.push("/courses/live-classes");
              }}
            >
              Live & Interactive
            </div>
            <div
              role="button"
              onClick={() => {
                history.push("/courses/all-levels");
              }}
            >
              Yoga for all levels
            </div>
            <div
              role="button"
              onClick={() => {
                history.push("/courses/all-health-conditions");
              }}
            >
              For your health needs
            </div>
          </div>
        </div> */}
        <h2 className="pt-3 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0 mb-3">
          What time do you want to do yoga?
        </h2>
        {/* <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          A regular practice helps you progress.
        </p> */}
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              type: "month",
            }}
            name="month"
            typeData="month"
            showTime={true}
          />
        </div>
        <ViewAllComponent
          link="/courses/monthly-classes"
          query="?name=&type=month"
          name="Monthly Yoga Courses"
          bannerText={[
            "A regular practice helps you progress.",
            "Find a monthly yoga class that works for you.",
          ]}
        />
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          10000+ people have done yoga with us.
        </h2>
        <div className="pt-5">
          <ReviewsList />
        </div>
        <h2 className="mt-2 text-y-yellow-dark text-lg md:text-xl font-bold mt-10">
          Tell us your details and book your first yoga session with us.
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Our yoga counselor will reach you to understand your needs and assign
          the best yoga class for you.
        </p>
        <div className="w-full md:w-2/5 pt-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <Input
                required
                name="name"
                label="Name"
                inputRef={register({ required: true })}
                className=""
              />
            </div>

            <div className="">
              <Input
                required
                name="phone"
                label="Phone"
                inputRef={register({ required: true })}
              />
            </div>

            {/* <div className="mt-2 flex flex-row justify-end">
            <Button type="submit">Submit</Button>
          </div> */}
            <div className="flex pt-0">
              <Button
                py="py-3"
                className="whitespace-nowrap"
                type="submit"
                // onClick={() => {
                //   countryCode === "IN"
                //     ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
                //     : window.open(
                //         "https://buy.stripe.com/5kAbLl8aw5vC3ao5kk",
                //         "_blank"
                //       );
                // }}
              >
                Submit & Book Trial
              </Button>
            </div>
          </Form>
        </div>

        <div
          className={cx(
            "px-0 flex flex-row  justify-between items-center overflow-x-auto mt-5 md:mt-10",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <h2 className="mt-2 text-y-yellow-dark">Personal Yoga Sessions</h2>
            <p
              className="pt-1 pb-4 text-black text-base font-bold"
              style={{ fontSize: "14px" }}
            >
              Join 1:1 for yoga sessions tailored to your health needs.
            </p>

            <div className="flex pt-0">
              <Button
                py="py-3"
                className="whitespace-nowrap"
                onClick={() => {
                  countryCode === "IN"
                    ? window.open(
                        "https://pages.razorpay.com/pl_LsCaM4vYXeZ6aE/view",
                        "_blank"
                      )
                    : window.open(
                        "https://buy.stripe.com/6oEaHhduQ8HOdP24gn",
                        "_blank"
                      );
                }}
              >
                Book 1:1 session @ {countryCode === "IN" ? "Rs.599" : "$ 15"}
              </Button>
            </div>
          </div>
          <div>
            <OneOnOneGraphic
              width={!isMobile ? 280 : 162}
              height={!isMobile ? 160 : 98}
            />
          </div>
        </div>

        <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 mt-5 md:mt-10 sm:w-2/4 mb-2 md:mb-0">
          <h2 className="mt-2 text-y-yellow-dark">
            Aahara : Personalized Nutrition Coaching
          </h2>
        </div>

        <div
          className={cx(
            "px-0 flex flex-row  justify-between items-start overflow-x-auto mt-0 md:mt-3",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-0 sm:w-2/4">
            <p
              className="pt-1 pb-4 text-black text-base font-bold"
              style={{ fontSize: "14px" }}
            >
              Elevate your well-being with tailored daily diet plans designed to
              manage health issues and help you achieve your health goals.
            </p>

            <div className="flex pt-0">
              <Button
                py="py-3"
                className="whitespace-nowrap"
                onClick={() => {
                  history.push("/aahara");
                }}
              >
                Learn More
              </Button>
            </div>
          </div>
          <div className="banner-diet">
            <img src={BannerDiet} alt="BannerDiet" />
          </div>
        </div>

        {/* <div
          className={cx(
            "px-0 flex flex-row  justify-between items-center overflow-x-auto sm:mt-10",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <h2 className="mt-2 text-y-yellow-dark">
              Book a trial class at only {countryCode === "IN" ? "₹ 50" : "$ 2"}
            </h2>
            <p
              className="pt-1 pb-4 text-black text-base font-bold"
              style={{ fontSize: "14px" }}
            >
              Let us help you find a yoga practice that works for you.
            </p>

            <div className="flex pt-0">
              <Button
                py="py-3"
                className="whitespace-nowrap"
                onClick={() => {
                  countryCode === "IN"
                    ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
                    : window.open(
                        "https://buy.stripe.com/5kAbLl8aw5vC3ao5kk",
                        "_blank"
                      );
                }}
              >
                Book
              </Button>
            </div>
          </div>
          <div>
            <OneOnOneGraphic
              width={!isMobile ? 280 : 162}
              height={!isMobile ? 160 : 98}
            />
          </div>
        </div> */}
        <h2 className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-5">
          Yoga for different health goals
        </h2>
        {/* <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Health issues can be managed well & even overcome doing yoga.
        </p> */}
        <div className="flex flex-nowrap w-full overflow-auto px-0 pb-10 pt-3">
          {healthConditionsData &&
            healthConditionsData.map((ele) => (
              <div
                onClick={() => {
                  handleClickHealthConditions(ele);
                  ReactGA.event({
                    category: "Category Details",
                    action: "Routing",
                    label: JSON.stringify(ele),
                  });
                  ReactPixel.track("track", {
                    category: "Category Details",
                    action: "Routing",
                    label: JSON.stringify(ele),
                  });
                }}
                style={{ marginRight: 20 }}
                className="text-xl bg-white whitespace-nowrap shadow-card-cream-sm cursor-pointer"
              >
                <div style={{ width: isMobile ? "180px" : "150px" }}>
                  <img
                    style={{ height: "120px", width: "100%" }}
                    src={ele.pic}
                    alt={ele.name}
                  />
                </div>
                <div
                  style={{
                    ...Styles.OrangeLinearGradient,
                  }}
                  className="text-center font-semibold text-white rounded-b-md"
                >
                  {ele.name}
                </div>
              </div>
            ))}
        </div>
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Featured Yoga Classes
        </h2>
        <div className="pt-5">
          <Banners />
        </div>
        <h2 className="pt-6 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Join the next yoga class at just{" "}
          {countryCode === "IN" ? "Rs.50" : "$5"}
        </h2>
        <div className="w-full px-0">
          <CardTabs />
        </div>
        <ViewAllComponent
          link="/courses/live-classes"
          query="?name=&type=live"
          name="Live Yoga Courses"
          bannerText={[
            "Explore different yoga styles.",
            "Connect with various teachers.",
            "Find the yoga practice that works for you.",
          ]}
        />
        <h2 className="pt-6 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          All our Yoga Teachers
        </h2>
        {/* <p
        className="pt-1 pb-4 text-black text-base font-bold"
        style={{ fontSize: "14px" }}
      >
        Get personal coaching 1:1 with yoga teachers.
      </p> */}
        <div className="w-full px-0 pt-3">
          <OneOnOnes />
        </div>
        <ViewAllComponent
          link="/1-1"
          query=""
          name="1:1 Yoga Courses"
          bannerText={[
            "Connect deeply & get personal coaching with 1:1 yoga classes.",
            "Find a teacher who you can connect to.",
          ]}
        />

        <h2 className="pt-6 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Find your yoga practice
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          When do you want to do yoga?
        </p>
        <div className="grid grid-rows-2 md:grid-rows-1 grid-flow-col gap-4">
          {!isMobile &&
            timingsData &&
            timingsData.map((ele) => (
              <div
                className="pb-3"
                style={{}}
                onClick={() => {
                  history.push(ele.link);
                }}
              >
                <div
                  className="cursor-pointer py-3 rounded shadow-md text-center font-semibold text-white text-sm"
                  style={{
                    ...Styles.OrangeLinearGradient,
                  }}
                >
                  {ele.text}
                  <br />
                  {ele.text1}
                </div>
              </div>
            ))}
          {isMobile &&
            timings &&
            timings.map((ele) => (
              <div
                className="pb-3"
                style={{}}
                onClick={() => {
                  history.push(ele.link);
                }}
              >
                <div
                  className="cursor-pointer py-3 rounded shadow-md text-center font-semibold text-white text-sm"
                  style={{
                    ...Styles.OrangeLinearGradient,
                  }}
                >
                  {ele.text}
                  <br />
                  {ele.text1}
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-between md:justify-start items-center w-full pt-5 text-center">
          <div className="text-lg md:text-xl text-y-yellow-dark font-bold ">
            Choose Time
          </div>
          <div className="cursor-pointer ml-0 md:ml-10">
            <div
              className="cursor-pointer py-0 px-5 rounded shadow-md text-center font-semibold text-white text-sm"
              style={{
                ...Styles.OrangeLinearGradient,
              }}
            >
              <select
                className="p-5 px-5 rounded text-center font-semibold outline-none"
                style={{
                  ...Styles.OrangeLinearGradient,
                }}
                onChange={(e) => {
                  history.push(`/courses/custom-time/${e.target.value}`);
                }}
              >
                {customTimes &&
                  customTimes.map((ele) => (
                    <option style={{ color: "black" }} value={ele.value}>
                      {ele.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        {/* <div className="text-lg md:text-xl text-y-yellow-dark font-bold mt-10">
          Get a Call Back
        </div>

        <div className="mt-5 px-7 sm:px-10 py-8 bg-white sm:rounded-md">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <Input
                required
                name="name"
                label="Name"
                inputRef={register({ required: true })}
              />
            </div>

            <div className="">
              <Input
                required
                name="phone"
                label="Phone"
                inputRef={register({ required: true })}
              />
            </div>

            <div className="mt-2 flex flex-row justify-end">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>

        <h2 className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-2">
          Yoga for kids
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Begin your yoga journey.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              levels: ["kids"],
              type: "month",
            }}
            name="kids"
            typeData="kids"
          />
        </div>
        <ViewAllComponent
          link="/kids"
          query="?name=&type=month"
          name="Monthly Yoga for Kids"
          filterArrData={{
            ...filterArrData,
            levels: [{ value: "kids", label: "Kids", checked: false }],
          }}
          filterCheckDatas={{
            ...FilterDatasEnum,
            levels: [
              { value: "beginner", label: "Beginner", checked: false },
              { value: "intermediate", label: "Intermediate", checked: false },
              { value: "advanced", label: "Advanced", checked: false },
              { value: "kids", label: "Kids", checked: true },
            ],
          }}
          bannerText={[
            "Begin your yoga journey.",
            "Join yoga classes designed for complete beginners.",
          ]}
        /> */}
        <h2 className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-2">
          Yoga for absolute beginners
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Begin your yoga journey.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              levels: ["beginner"],
              type: "month",
            }}
            name="beginner"
            typeData="beginner"
          />
        </div>
        <ViewAllComponent
          link="/courses/beginner-courses"
          query="?name=&type=month"
          name="Monthly Yoga for Beginners"
          filterArrData={{
            ...filterArrData,
            levels: [{ value: "beginner", label: "Beginner", checked: false }],
          }}
          filterCheckDatas={{
            ...FilterDatasEnum,
            levels: [
              { value: "beginner", label: "Beginner", checked: true },
              { value: "intermediate", label: "Intermediate", checked: false },
              { value: "advanced", label: "Advanced", checked: false },
            ],
          }}
          bannerText={[
            "Begin your yoga journey.",
            "Join yoga classes designed for complete beginners.",
          ]}
        />
        <h2 className="pt-6 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Yoga for Weight loss
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Yoga helps you manage your body better.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              healthConditions: [
                {
                  value: "Weight loss",
                  label: "Weight loss",
                  checked: false,
                },
              ],
              type: "month",
            }}
            name="weight"
            typeData="weight"
          />
        </div>
        <ViewAllComponent
          link="/courses/weightloss-classes"
          query="?name=&type=month"
          name="Monthly Yoga for Weight loss"
          filterArrData={{
            ...filterArrData,
            healthConditions: [
              { value: "Weight loss", label: "Weight loss", checked: false },
            ],
          }}
          filterCheckDatas={{
            ...FilterDatasEnum,
            healthConditions: [
              ...FilterDatasEnum.healthConditions,
              { value: "Weight loss", label: "Weight loss", checked: true },
            ],
          }}
          bannerText={[
            "Yoga helps you manage your body better.",
            "Join weight loss programs designed for you.",
          ]}
        />
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Yoga for pregnancy
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Relieve physical discomforts and prepare for childbirth.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              healthConditions: [
                { value: "pregnancy", label: "Pregnancy", checked: false },
              ],
              type: "month",
            }}
            name="Pregnancy"
            typeData="pregnancy"
          />
        </div>
        <ViewAllComponent
          link="/courses/pregnancy-classes"
          query="?name=&type=month"
          name="Yoga for pregnancy"
          filterArrData={{
            ...filterArrData,
            healthConditions: [
              { value: "pregnancy", label: "Pregnancy", checked: false },
            ],
          }}
          filterCheckDatas={{
            ...FilterDatasEnum,
            healthConditions: [
              ...FilterDatasEnum.healthConditions,
              { value: "Pregnancy", label: "Pregnancy", checked: true },
            ],
          }}
          bannerText={[
            "Yoga helps you manage your body better.",
            "Relieve physical discomforts and prepare for childbirth.",
          ]}
        />
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Yoga for experienced practitioners
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Deepen your yoga practice.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              levels: ["intermediate", "advanced"],
              type: "month",
            }}
            name="experiance"
            typeData="advanced"
          />
        </div>
        <ViewAllComponent
          link="/courses/advanced-courses"
          query="?name=&type=month"
          name="Monthly Yoga for Experienced Practitioners"
          bannerText={[
            "Deepen your yoga practice.",
            "Classes designed for intermediate & advanced yoga practitioners.",
          ]}
          filterArrData={{
            ...filterArrData,
            levels: [
              { value: "intermediate", label: "Intermediate", checked: false },
              { value: "advanced", label: "Advanced", checked: false },
            ],
          }}
          filterCheckDatas={{
            ...FilterDatasEnum,
            levels: [
              { value: "beginner", label: "Beginner", checked: false },
              { value: "intermediate", label: "Intermediate", checked: true },
              { value: "advanced", label: "Advanced", checked: true },
            ],
          }}
        />
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Courses you can join
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Short & long courses to progress through the yoga journey.
        </p>
        <div>
          <MonthlyClasses
            filterArr={{
              ...filterArrData,
              type: "month",
            }}
            name="fixed"
            typeData="term"
          />
        </div>
        <ViewAllComponent
          link="/courses/term-classes"
          query="?name=&type=month"
          name="Courses you can join"
          bannerText={[
            "A regular practice helps you progress.",
            "Find a monthly yoga class that works for you.",
          ]}
        />

        <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 mt-5 md:mt-10 sm:w-2/4 mb-2 md:mb-0">
          <h2 className="mt-2 text-y-yellow-dark">
            Yoga Nidra: Relax & Meditate
          </h2>
        </div>

        <div
          className={cx(
            "px-0 flex flex-row  justify-between items-center overflow-x-auto mt-0 md:mt-10",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="yoga-logo">
            <img src={BannerYoga} alt="BannerYoga" />
          </div>
          <div className="text-lg md:text-xl font-bold pl-4 pt-0 sm:pt-0 sm:w-2/4">
            <p
              className="pt-1 pb-4 text-black text-base font-bold"
              style={{ fontSize: "14px" }}
            >
              Yoga Nidra helps you relax completely, get recharged and explore
              your inner Self. Download the app now.
            </p>
          </div>
        </div>

        <div
          className="flex justify-center items-center mt-0 mb-5"
          style={{ marginTop: isMobile ? 20 : 40 }}
        >
          <div className="mr-5">
            <a href="https://play.google.com/store/apps/details?id=com.yoganidra">
              <img
                src={Google}
                alt="google"
                style={{ width: isMobile ? "100%" : "70%" }}
              />
            </a>
          </div>
          <div className="ml-5">
            <a href="https://apps.apple.com/us/app/yoga-nidra-relax-meditate/id6450855935">
              <img
                src={Apple}
                alt="google"
                style={{ width: isMobile ? "100%" : "70%" }}
              />
            </a>
          </div>
        </div>

        <div
          className={`flex ${
            isMobile ? "justify-end" : "justify-center"
          } items-center px-0 pt-3 cursor-pointer mb-5`}
          onClick={() => history.push("yoganidra")}
        >
          <div className="pr-2 text-base text-y-yellow-dark font-bold">
            Learn more
          </div>
          <div>
            <svg
              width="25"
              height="25"
              viewBox="0 0 16 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.624989 4.50001L13.8612 4.50001L11.6686 6.68201C11.4239 6.9255 11.423 7.32122 11.6665 7.56588C11.91 7.81056 12.3057 7.81147 12.5504 7.568L15.8161 4.31801C15.8163 4.31782 15.8165 4.31761 15.8167 4.31742C16.0607 4.07392 16.0615 3.67692 15.8167 3.43261C15.8165 3.43242 15.8164 3.4322 15.8162 3.43201L12.5504 0.182024C12.3058 -0.0614138 11.9101 -0.0605698 11.6665 0.184148C11.423 0.428803 11.424 0.824521 11.6686 1.06802L13.8612 3.25001L0.624989 3.25001C0.279801 3.25001 -1.14441e-05 3.52983 -1.14441e-05 3.87501C-1.14441e-05 4.2202 0.279801 4.50001 0.624989 4.50001Z"
                fill="#E56F2C"
              />
            </svg>
          </div>
        </div>

        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          Blogs
        </h2>
        {/* <p
        className="pt-1 pb-4 text-black text-base font-bold"
        style={{ fontSize: "14px" }}
      >
        Buy class packs and explore classes across teachers.
      </p> */}
        <div>
          <BlogPage />
        </div>
        <ViewAllComponent
          link="/blog/articles"
          query=""
          name="1:1 Yoga Courses"
          bannerText={[
            "Connect deeply & get personal coaching with 1:1 yoga classes.",
            "Find a teacher who you can connect to.",
          ]}
        />
        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
          All Yoga practices
        </h2>
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Asana, Pranayama, Mudra, Mantra
        </p>
        <div>
          <BlogPage type="asana" />
        </div>
        <ViewAllComponent
          link="/blog/yoga-practices"
          query=""
          name="1:1 Yoga Courses"
          bannerText={[
            "Connect deeply & get personal coaching with 1:1 yoga classes.",
            "Find a teacher who you can connect to.",
          ]}
        />

        <div
          className={cx(
            "px-0 flex flex-row md:flex-row-reverse justify-between items-center overflow-x-auto sm:mt-10",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <p className="mt-2 text-y-yellow-dark">
              Talk to our yoga counselors.
              <br />
              <br />
              We are here to help you.
              <br />
              <br />
            </p>
            {/* <p
            className="sm:block mt-2 text-black text-base"
            style={{ fontSize: "14px" }}
          >
            Tell us about yourself and let us guide you to the right yoga class.
          </p> */}
            <div className="flex">
              <ReactWhatsapp
                number="+917975322831"
                message={`Hi, I want to do yoga. About me - `}
                className=""
              >
                <button
                  className={cx(
                    "w-full mt-5 focus:outline-none",
                    `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                    "cursor-pointer text-white flex items-center justify-center"
                  )}
                  style={{
                    ...Styles.OrangeLinearGradient,
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src="/img/whatsapp.png"
                    height={40}
                    width={40}
                    alt="whatspp"
                    style={{ marginRight: 5 }}
                  />
                  Whatsapp Us
                </button>
              </ReactWhatsapp>
            </div>
          </div>
          <div>
            <YogaSun
              width={!isMobile ? 230 : 353}
              height={!isMobile ? 100 : 301}
            />
          </div>
        </div>

        <h2 className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10 pb-4">
          FAQs - Frequently Asked Questions
        </h2>
        <Faq />

        <HomeFoot />

        {/* <div
          className={cx(
            "px-0 flex flex-row md:flex-row-reverse justify-between items-center overflow-x-auto sm:mt-10",
            "sm:h-full sm:w-4/5"
          )}
        >
          <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <p className="mt-2 text-y-yellow-dark">
              Talk to our chat bot
              <br />
              <br />
              Tell us about yourself and your requirements.
              <br />
              <br />
              Let us guide you to the right yoga practice for you.
            </p>
            <p
            className="sm:block mt-2 text-black text-base"
            style={{ fontSize: "14px" }}
          >
            Tell us about yourself and let us guide you to the right yoga class.
          </p>
            <div className="flex pt-5">
              <Button
                py="py-3"
                className="whitespace-nowrap"
                onClick={() => {
                  history.push("/findyouryoga");
                }}
              >
                Let's Go
              </Button>
            </div>
          </div>
          <div>
            <YogaSun
              width={!isMobile ? 230 : 353}
              height={!isMobile ? 100 : 301}
            />
          </div>
        </div> */}
        {/* <div className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
        Subscriptions
      </div>
      <p
        className="pt-1 pb-4 text-black text-base font-bold"
        style={{ fontSize: "14px" }}
      >
        Buy class packs and explore classes across teachers.
      </p>
      <div>
        <SubscriptionList />
      </div>
      <ViewAllComponent
        link="/subscriptions/course"
        query=""
        name="1:1 Yoga Courses"
        bannerText={[
          "Connect deeply & get personal coaching with 1:1 yoga classes.",
          "Find a teacher who you can connect to.",
        ]}
      /> */}
      </div>
    </Suspense>
  );
}
